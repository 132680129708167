export const listJubilados = [
  {
    content: "Deberá informar a la asociación con anterioridad al giro de la cuota anual; no pudiendo acogerse a este beneficio con carácter retroactivo.",
  },
  {
    content: "Demostrar un mínimo de 6 meses en estado de jubilado o prejubilado.",
  },
  {
    content: "Documentación a enviar:",
    children: [
      {
        content: "<strong>Jubilado:</strong> Certificado expedido por el Director Provincial del Instituto Nacional de la Seguridad Social"
      },
      {
        content: "<strong>Prejubilado:</strong> Certificado expedido por su entidad"
      }
    ]
  },
  {
    content: "E-mail de envío: <a href='&#109;&#097;&#105;&#108;&#116;&#111;:&#102;&#097;&#099;&#116;&#117;&#114;&#097;&#099;&#105;&#111;&#110;&#064;&#101;&#102;&#112;&#097;&#046;&#101;&#115;'>" +
        "&#102;&#097;&#099;&#116;&#117;&#114;&#097;&#099;&#105;&#111;&#110;&#064;&#101;&#102;&#112;&#097;&#046;&#101;&#115;</a>.",
  },
  {
    content: "Exentos de recertificarse.",
  },
]

export const listWithChildren = [
  {
    content: "Exentos de la cuota anual, teniendo en cuenta:",
    children: [
      {
        content:
          "Informar a la asociación con anterioridad al giro de la cuota anual; no pudiendo acogerse a este beneficio con carácter retroactivo.",
      },
      {
        content: "Demostrar un mínimo de 6 meses en estado de desempleo.",
      },
    ],
  },
  {
    content:
      "Documentación a enviar: Renovación de la Demanda (DARDE).",
  },
  {
    content: "E-mail de envío: <a href='&#109;&#097;&#105;&#108;&#116;&#111;:&#102;&#097;&#099;&#116;&#117;&#114;&#097;&#099;&#105;&#111;&#110;&#064;&#101;&#102;&#112;&#097;&#046;&#101;&#115;'>" +
        "&#102;&#097;&#099;&#116;&#117;&#114;&#097;&#099;&#105;&#111;&#110;&#064;&#101;&#102;&#112;&#097;&#046;&#101;&#115;</a>.",
  },
  {
    content: "Cada año deberán recertificarse y ratificar el código ético.",
  },
]
