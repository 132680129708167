import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import List from "../components/List"
import { list, listWithChildren } from "../data/retiree-benefits-data"

export default function RetireeBenefits() {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    { label: "Ventajas para jubilados/prejubilados y desempleados " },
  ]
  return (
    <Layout>
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Ventajas para jubilados/prejubilados y desempleados"
          icon="far fa-edit"
        />
        <TitleBlock
          title="Jubilados/prejubilados"
          icon="far fa-edit"
          className="RetireeBenefits-subtitle"
        />
        <List items={list} className="RetireeBenefits-list margin-b" />
        <TitleBlock
          title="Desempleados"
          icon="far fa-edit"
          className="RetireeBenefits-subtitle"
        />
        <List items={listWithChildren} className="RetireeBenefits-list" />
      </div>
    </Layout>
  )
}
